import {
  faCalendar, faGlassMartiniAlt, faHeart, faHeartbeat, faThermometerHalf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Row, Space, Table, Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';

import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import React from 'react';

import connect from '../../connect';
import * as routes from '../../routes_';
import selectors from '../../selectors';

const mapsStateToProps = (state: any, { filter }) => {
  const props = {
    contracts: state.contracts.entities,
    employees: state.employees.entities,
    exams: selectors.exams(state, { filter }),
    isLoading: state.exams.fetchingExamsStatus === 'requested',
    user: state.user,
    filter,
  };

  return props;
};

@connect(mapsStateToProps) class ExamsTable extends React.Component<any, any> {
  handleRowClick = ({ currentTarget }) => {
    const { push } = this.props;
    const id = currentTarget.getAttribute('data-row-key');
    push(routes.exam(id));
  };

  render() {
    const {
      contracts,
      employees,
      exams,
      changeJournalData,
      isLoading,
    } = this.props;

    const columns: any[] = [
      {
        title: 'Дата и время',
        dataIndex: 'updatedAt',
        render: (text, record) => {
          return moment
            .utc(record.updatedAt)
            .zone(-(record.terminal_local_time_offset
              || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
            .format('DD.MM.YY k:mm:ss');
        },
        defaultSortOrder: 'descend',
        sorter: (exam1, exam2): any => {
          if (moment(exam1.updatedAt).isBefore(exam2.updatedAt)) {
            return -1;
          }
          if (moment(exam1.updatedAt).isAfter(exam2.updatedAt)) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: '№',
        dataIndex: 'id',
      },
      {
        title: '№ п.',
        dataIndex: 'attempt_number',
      },
      {
        title: 'Тип осмотра',
        render: (text, { ExamType }) => {
          return ExamType === 1
            ? 'предрейсовый'
            : ExamType === 2
              ? 'послерейсовый'
              : ExamType === 3
                ? 'предсменный' :
                ExamType === 4
                  ? 'послесменный'
                  : 'в течение рабочего дня';
        },
      },
      {
        title: 'Ф.И.О.',
        render: (text, record) => {
          const employee = employees.users[record.Worker];
          return [
            employee.lastName,
            employee.firstName,
            employee.patronymic,
          ].join(' ');
        },
      },
      {
        title: 'Дата рождения',
        render: (text, { Worker }) => moment(employees.users[Worker].dateOfBirth).format('DD.MM.YY'),
      },
      {
        title: () => <>Идентификатор/<br />таб. номер</>,
        render: (text, record) => {
          const employee = employees.users[record.Worker];
          return employee.id;
        },
      },
      {
        title: 'Организация',
        render: (text, record) => {
          const contract = contracts.contracts[record.Contract];
          const customer = contracts.companies[contract.Customer];
          return customer.shortName;
        },
        onFilter: (value, exam) => {
          const contract = contracts.contracts[exam.Contract];
          const company = contracts.companies[contract.Customer];
          return company.shortName.indexOf(value) === 0;
        },
        filters: Object
          .keys(fp.groupBy('shortName')(contracts.companies))
          .map((key) => ({ text: key, value: key })),
      },
      {
        title: 'Подразделение',
        render: (text, record) => {
          const contract = contracts.contracts[record.Contract];
          const { Subdivisions: contractSubdivisions } = contract;
          const { Subdivisions: userSubdivisions } = employees.users[record.Worker];
          const subdivisions = _.intersection(contractSubdivisions, userSubdivisions);
          const currentSubdivision = contracts.subdivisions[subdivisions[0]];
          return currentSubdivision.title;
        },
        onFilter: (value, exam) => {
          const contract = contracts.contracts[exam.Contract];
          const { Subdivisions: contractSubdivisions } = contract;
          const { Subdivisions: userSubdivisions } = employees.users[exam.Worker];
          const subdivisions = _.intersection(contractSubdivisions, userSubdivisions);
          const subdivision = contracts.subdivisions[subdivisions[0]];
          return subdivision.title.indexOf(value) === 0;
        },
        filters: Object
          .keys(fp.groupBy('title')(contracts.subdivisions))
          .map((key) => ({ text: key, value: key })),
      },
      {
        title: 'Группа риска',
        render: (text, { Worker }) => {
          const employee = employees.users[Worker];
          const riskGroup = employee.RiskGroup && employees.riskGroup[employee.RiskGroup];
          const references = riskGroup?.References.map((refId) => employees.references[refId]);
          const riskReferences = riskGroup
            ? {
              SYS: _.find(references, ['IndicatorTypeId', 1]),
              DIA: _.find(references, ['IndicatorTypeId', 2]),
              PULSE: _.find(references, ['IndicatorTypeId', 3]),
              TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
              ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            }
            : {
              SYS: false,
              DIA: false,
              PULSE: false,
              TEMPERATURE: false,
              ALCOHOL: false,
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            };

          return (
            <Space direction="vertical" size="small">
              {riskReferences.AGE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faCalendar} />
                  </Row>
                )}
              {riskReferences.ALCOHOL
                && (
                  <Row>
                    <FontAwesomeIcon icon={faGlassMartiniAlt} />
                  </Row>
                )}
              {(riskReferences.SYS || riskReferences.DIA)
                && (
                  <Row>
                    <FontAwesomeIcon icon={faHeart} />
                  </Row>
                )}
              {riskReferences.PULSE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faHeartbeat} />
                  </Row>
                )}
              {riskReferences.TEMPERATURE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faThermometerHalf} />
                  </Row>
                )}
            </Space>
          );
        },
        onFilter: (value, exam) => {
          const employee = employees.users[exam.Worker];
          const riskGroup = employee.RiskGroup && employees.riskGroup[employee.RiskGroup];
          const references = riskGroup?.References.map((refId) => employees.references[refId]);
          const riskReferences = riskGroup
            ? {
              SYS: _.find(references, ['IndicatorTypeId', 1]),
              DIA: _.find(references, ['IndicatorTypeId', 2]),
              PULSE: _.find(references, ['IndicatorTypeId', 3]),
              TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
              ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            }
            : {
              SYS: false,
              DIA: false,
              PULSE: false,
              TEMPERATURE: false,
              ALCOHOL: false,
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            };
          return riskReferences[String(value)];
        },
        filters: [
          { text: 'По возрасту', value: 'AGE' },
          { text: 'По САД', value: 'SYS' },
          { text: 'По ДАД', value: 'DIA' },
          { text: 'По пульсу', value: 'PULSE' },
          { text: 'По алкоголю', value: 'ALCOHOL' },
          { text: 'По температуре', value: 'TEMPERATURE' },
        ],
      },
      {
        title: 'Жалобы и показатели',
        render: (text, record) => {
          const {
            sysValue,
            diaValue,
            pulseValue,
            temperatureValue,
            alcoholValue,
            isSysNormal,
            isDiaNormal,
            isPulseNormal,
            isTemperatureNormal,
            isAlcoholNormal,
          } = record;
          const bloodPressure = (sysValue && diaValue) && [sysValue, diaValue].join('/');

          return (
            <>
              <Row gutter={4}>
                <Col>
                  {record.complaints
                    ? <p style={{ color: 'red' }}>{record.complaints.slice(0, 16) + '...'}</p>
                    : 'жалоб нет'}
                </Col>
              </Row>
              <Row gutter={4}>
                <Col span={6}>
                  <FontAwesomeIcon
                    icon={faHeart}
                    color={((isSysNormal && isDiaNormal) ? '' : 'red')}
                  />
                </Col>
                <Col>
                  {bloodPressure}
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <FontAwesomeIcon icon={faHeartbeat} color={(isPulseNormal ? '' : 'red')} />
                </Col>
                <Col>
                  {pulseValue}
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <FontAwesomeIcon icon={faThermometerHalf} color={(isTemperatureNormal ? '' : 'red')} />
                </Col>
                <Col>
                  {temperatureValue}
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <FontAwesomeIcon icon={faGlassMartiniAlt} color={(isAlcoholNormal ? '' : 'red')} />
                </Col>
                <Col>
                  {alcoholValue}
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        title: 'Статус',
        render: (text, record) => record.allowance !== null && (
          <span>
            {
              record.status === 'pendingAllowance'
                ? 'Ожидает решения'
                : record.status === 'signed'
                  ? <Tag
                    color={record.allowance ? 'green' : '#f50'}
                  > {`${record.allowance ? 'пройден' : 'выявлены признаки'}`}
                  </Tag>
                  : 'Проводится'
            }
          </span>
        ),
      },
    ];

    return (
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={exams}
        rowKey="id"
        pagination={{ responsive: true, position: ['bottomLeft'], defaultPageSize: 10 }}
        onRow={() => ({
          onClick: (event) => {
            document.body.style.cursor = 'auto';
            this.handleRowClick(event);
          },
        })}
        size='small'
        onChange={
          (_p, _f, _s, extra) => {
            changeJournalData({ data: extra.currentDataSource });
          }
        }
      />
    );
  }
}

export default ExamsTable;
