import {
  faCalendar,
  faGlassMartiniAlt,
  faHeart, faHeartbeat, faThermometerHalf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import React from 'react';

import connect from '../../connect';
import selectors from '../../selectors';

const mapsStateToProps = (state: any, { filter }) => {
  const props = {
    contracts: state.contracts.entities,
    employees: state.employees.entities,
    selectedEmployees: selectors.employees(state, { filter }),
    user: state.user,
  };
  return props;
};

@connect(mapsStateToProps) class UsersTable extends React.Component<any, any> {
  handleRowClick = () => {
  };

  render() {
    const {
      contracts,
      employees,
      selectedEmployees,
    } = this.props;

    const columns: ColumnProps<any>[] = [
      {
        title: 'Ф.И.О.',
        render: (text, employee) => [
          employee.lastName,
          employee.firstName,
          employee.patronymic,
        ].join(' '),
        defaultSortOrder: 'descend',
        sorter: (employee1, employee2): any => {
          const fullName1 = [
            employee1.lastName,
            employee1.firstName,
            employee1.patronymic,
          ].join(' ');
          const fullName2 = [
            employee2.lastName,
            employee2.firstName,
            employee2.patronymic,
          ].join(' ');
          if (fullName1 > fullName2) {
            return -1;
          }
          if (fullName1 < fullName2) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: 'Дата рождения',
        dataIndex: 'dateOfBirth',
        render: (text) => moment(text).format('DD.MM.YY'),
      },
      {
        title: () => <>Идентификатор/<br />таб. номер</>,
        dataIndex: 'id',
        render: (text, employee) => employee.id,
        sorter: (employee1, employee2): any => {
          if (employee1.id > employee2.id) {
            return -1;
          }
          if (employee1.id < employee2.id) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: 'Организация',
        render: (text, employee) => {
          const subdivision = contracts.subdivisions[employee.Subdivisions[0]];
          const contract = contracts.contracts[subdivision.ContractId];
          const customer = contracts.companies[contract.Customer];
          return customer.shortName;
        },
        onFilter: (value, employee) => {
          const subdivision = contracts.subdivisions[employee.Subdivisions[0]];
          const contract = contracts.contracts[subdivision.ContractId];
          const customer = contracts.companies[contract.Customer];
          return customer.shortName.indexOf(value) === 0;
        },
        filters: Object
          .keys(fp.groupBy('shortName')(contracts.companies))
          .map((key) => ({ text: key, value: key })),
      },
      {
        title: 'Подразделение',
        render: (text, employee) => {
          const subdivision = contracts.subdivisions[employee.Subdivisions[0]];
          return subdivision.title;
        },
        onFilter: (value, employee) => {
          const subdivision = contracts.subdivisions[employee.Subdivisions[0]];
          return subdivision.title.indexOf(value) === 0;
        },
        filters: Object
          .keys(fp.groupBy('title')(contracts.subdivisions))
          .map((key) => ({ text: key, value: key })),
      },
      {
        title: 'Группа риска',
        render: (text, employee) => {
          const riskGroup = employee.RiskGroup && employees.riskGroup[employee.RiskGroup];
          const references = riskGroup?.References.map((refId) => employees.references[refId]);
          const riskReferences = riskGroup
            ? {
              SYS: _.find(references, ['IndicatorTypeId', 1]),
              DIA: _.find(references, ['IndicatorTypeId', 2]),
              PULSE: _.find(references, ['IndicatorTypeId', 3]),
              TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
              ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            }
            : {
              SYS: false,
              DIA: false,
              PULSE: false,
              TEMPERATURE: false,
              ALCOHOL: false,
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            };

          return (
            <Space direction="vertical" size="small">
              {riskReferences.AGE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faCalendar} />
                  </Row>
                )}
              {riskReferences.ALCOHOL
                && (
                  <Row>
                    <FontAwesomeIcon icon={faGlassMartiniAlt} />
                  </Row>
                )}
              {(riskReferences.SYS || riskReferences.DIA)
                && (
                  <Row>
                    <FontAwesomeIcon icon={faHeart} />
                  </Row>
                )}
              {riskReferences.PULSE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faHeartbeat} />
                  </Row>
                )}
              {riskReferences.TEMPERATURE
                && (
                  <Row>
                    <FontAwesomeIcon icon={faThermometerHalf} />
                  </Row>
                )}
            </Space>
          );
        },
        onFilter: (value, employee) => {
          const riskGroup = employee.RiskGroup && employees.riskGroup[employee.RiskGroup];
          const references = riskGroup?.References.map((refId) => employees.references[refId]);
          const riskReferences = riskGroup
            ? {
              SYS: _.find(references, ['IndicatorTypeId', 1]),
              DIA: _.find(references, ['IndicatorTypeId', 2]),
              PULSE: _.find(references, ['IndicatorTypeId', 3]),
              TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
              ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            }
            : {
              SYS: false,
              DIA: false,
              PULSE: false,
              TEMPERATURE: false,
              ALCOHOL: false,
              AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
            };
          return riskReferences[String(value)];
        },
        filters: [
          { text: 'По возрасту', value: 'AGE' },
          { text: 'По алкоголю', value: 'ALCOHOL' },
          { text: 'По САД', value: 'SYS' },
          { text: 'По ДАД', value: 'DIA' },
          { text: 'По пульсу', value: 'PULSE' },
          { text: 'По температуре', value: 'TEMPERATURE' },
        ],
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={selectedEmployees}
        rowKey="id"
        pagination={{ responsive: true, position: ['bottomLeft'], defaultPageSize: 10 }}
        onChange={(pagination, filters, sorter, extra) => console.log({
          pagination, filters, sorter, extra,
        })}
      />
    );
  }
}

export default UsersTable;
