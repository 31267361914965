import { API_ROOT } from './config';

export const users = () => [API_ROOT, 'users'].join('/');
export const user = (userId: number) => [API_ROOT, 'users', userId].join('/');
export const login = () => [API_ROOT, 'login'].join('/');
export const certAuth = () => [API_ROOT, 'certauth'].join('/');
export const logout = () => [API_ROOT, 'logout'].join('/');
export const company = (companyId: number) => [API_ROOT, 'companies', companyId].join('/');
export const contracts = () => [API_ROOT, 'contracts'].join('/');
export const exams = () => [API_ROOT, 'exams'].join('/');
export const exam = (id: number) => [API_ROOT, 'exams', id].join('/');
export const examReport = (examId: number) => [API_ROOT, 'exams', examId, 'report'].join('/');
export const examPhotoSys = (id: number) => [API_ROOT, 'exams', id, 'photos', 'sys'].join('/');
export const examPhotoDia = (id: number) => [API_ROOT, 'exams', id, 'photos', 'dia'].join('/');
export const examPhotoPulse = (id: number) => [API_ROOT, 'exams', id, 'photos', 'pulse'].join('/');
export const examPhotoTemperature = (id: number) => [API_ROOT, 'exams', id, 'photos', 'temperature'].join('/');
export const examPhotoAlcohol = (id: number) => [API_ROOT, 'exams', id, 'photos', 'alcohol'].join('/');
export const examVideo = (id: number) => [API_ROOT, 'exams', id, 'video'].join('/');
export const examReportSignature = (id: number) => [API_ROOT, 'exams', id, 'report', 'signature'].join('/');
export const userAvatar = (id: number) => [API_ROOT, 'users', id, 'avatar'].join('/');
export const journals = () => [API_ROOT, 'journals'].join('/');
