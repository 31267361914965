import {
  Badge, Button, Col, Descriptions,
  Modal, Row, Tag,
  Timeline,
  Upload
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router';

import * as api from '../../api';
import connect from '../../connect';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const NURSE_ROLE_ID = 2;

const mapsStateToProps = (state: any, ownProps) => {
  const {
    match: {
      params: {
        id: examId,
      },
    },
  } = ownProps;

  const {
    user,
    exams: {
      entities: examsEntities,
      allowanceChangingStatus,
    },
    contracts: { entities: contractsEntities },
    employees: { entities: employeesEntities },
  } = state;

  const rightToDisplayHealthcareData: boolean = user.Roles.includes(NURSE_ROLE_ID);
  const submitting = allowanceChangingStatus === 'requested';

  const exam = examsEntities.exams[examId];
  const employee = employeesEntities.users[exam.Worker];
  const nurse = contractsEntities.users[exam.Nurse];
  const riskGroup = employee.RiskGroup && employeesEntities.riskGroup[employee.RiskGroup];
  const references = riskGroup?.References.map((refId) => employeesEntities.references[refId]);
  const contract = contractsEntities.contracts[exam.Contract];
  const customer = contractsEntities.companies[contract.Customer];
  const contractor = contractsEntities.companies[contract.Contractor];

  const props = {
    submitting,
    rightToDisplayHealthcareData,
    exam,
    employee,
    nurse,
    riskGroup,
    references,
    customer,
    contractor,
    allowanceChangingStatus,
  };
  return props;
};

@connect(mapsStateToProps)
class ExamPage extends React.Component<any, any> {
  examPageStart!: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
    };
  }

  componentDidMount() {
    this.examPageStart.scrollIntoView({ behavior: 'smooth' });
  }

  setAllowancesSuccess = (id, allowance) => () => {
    const { changeExamAllowance } = this.props;
    changeExamAllowance({ id, allowance });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  // eslint-disable-next-line react/no-unused-state
  handleChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const {
      exam,
      employee,
      nurse,
      riskGroup,
      references,
      customer,
      contractor,
      goBack,
      submitting,
    } = this.props;

    const currentTime = new Date();
    const currentTimeOffset = -currentTime.getTimezoneOffset();

    const nurse_local_time_offset = _.isNil(exam.nurse_local_time_offset) ? currentTimeOffset : exam.nurse_local_time_offset;

    const terminal_local_time_offset = _.isNil(exam.terminal_local_time_offset) ? currentTimeOffset : exam.terminal_local_time_offset;

    const status = exam.status || '';

    let statusText = '';
    switch (status) {
      case 'active':
        statusText = 'Проводится';
        break;
      case 'pendingAllowance':
        statusText = 'Ожидание решения о допуске';
        break;
      case 'pendingEmployeeSign':
        statusText = 'Ожидание подписи работника';
        break;
      case 'pendingNurseSign':
        statusText = 'Ожидание подписи медицинского работника';
        break;
      case 'signed':
        statusText = 'Завершен';
        break;
      default:
        break;
    }

    const avatar = (
      <img
        alt="Визуальный осмотр"
        src="/avatar.png"
        style={{ maxHeight: '180px' }}
      />
    );

    const riskReferences = riskGroup
      ? {
        SYS: _.find(references, ['IndicatorTypeId', 1]),
        DIA: _.find(references, ['IndicatorTypeId', 2]),
        PULSE: _.find(references, ['IndicatorTypeId', 3]),
        TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
        ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
        AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
      }
      : {
        SYS: false,
        DIA: false,
        PULSE: false,
        TEMPERATURE: false,
        ALCOHOL: false,
        AGE: moment().diff(employee.dateOfBirth, 'years') >= 55,
      };

    const isSysNormal = exam.sysValue >= exam.sysMin && exam.sysValue <= exam.sysMax;
    const isDiaNormal = exam.diaValue >= exam.diaMin && exam.diaValue <= exam.diaMax;
    const isPulseNormal = exam.pulseValue >= exam.pulseMin && exam.pulseValue <= exam.pulseMax;
    const isTemperatureNormal = exam.temperatureValue >= exam.temperatureMin
      && exam.temperatureValue <= exam.temperatureMax;
    const isAlcoholNormal = exam.alcoholValue >= exam.alcoholMin
      && exam.alcoholValue <= exam.alcoholMax;

    const { is_control_alcohol_photo_similar, is_control_pressure_photo_similar, is_control_temperature_photo_similar } = exam;

    const { previewVisible, previewImage } = this.state;

    const fileList: Array<any> = [
      {
        uid: '-1',
        name: 'image.png',
        status: is_control_pressure_photo_similar ? 'done' : 'error',
        url: api.examPhotoSys(exam.id),
        showRemoveIcon: false,
      },
      {
        uid: '-3',
        name: 'image.png',
        status: is_control_pressure_photo_similar ? 'done' : 'error',
        showRemoveIcon: false,
        url: api.examPhotoPulse(exam.id),
      },
      {
        uid: '-4',
        name: 'image.png',
        status: is_control_temperature_photo_similar ? 'done' : 'error',
        showRemoveIcon: false,
        url: api.examPhotoTemperature(exam.id),
      },
      {
        uid: '-5',
        name: 'image.png',
        status: is_control_alcohol_photo_similar ? 'done' : 'error',
        showRemoveIcon: false,
        url: api.examPhotoAlcohol(exam.id),
      },
    ];

    const { ExamType: examTypeId } = exam;
    const examType = examTypeId === 1
      ? 'предрейсовый'
      : examTypeId === 2
        ? 'послерейсовый'
        : examTypeId === 3
          ? 'предсменный'
          : examTypeId === 4
            ? 'послесменный'
            : 'в течение рабочего дня (смены)';

    const allowance = exam.allowance ? 'допущен' : 'не допущен';
    let conclusionSuffix = (examTypeId == 1 || examTypeId == 3)
      ? `, к исполнению трудовых обязанностей ${allowance}`
      : '';
    const conclusionText = `прошел ${examType} медицинский осмотр${conclusionSuffix}${(!exam.allowance) ? ', выявлены признаки' : ''}`

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(el: HTMLDivElement) => this.examPageStart = el} className="clearfix">

        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} width="70%">
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Row>
          <Descriptions
            title={(
              <div>
                {['Информация об осмотре №', exam.id].join(' ')}
                {' '}
                &nbsp;
                <Button type="primary" onClick={() => goBack()}>Назад</Button>
              </div>
            )}
            layout="vertical"
            bordered
            column={5}
          >
            <Descriptions.Item label="Дата и время терминала">
              {
                [
                  moment
                    .utc(exam.updatedAt)
                    .zone(-(terminal_local_time_offset
                      || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                    .format('DD.MM.YY k:mm:ss'),
                  ' (UTC',
                  terminal_local_time_offset > 0 ? '+' : '-',
                  terminal_local_time_offset / 60,
                  ')'
                ].join('')
              /* {} */}
            </Descriptions.Item>
            <Descriptions.Item label="Дата и время медицинского работника">
              {
                [
                  moment
                    .utc(exam.updatedAt)
                    .zone(-(nurse_local_time_offset
                      || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                    .format('DD.MM.YY k:mm:ss'),
                  ' (UTC',
                  nurse_local_time_offset > 0 ? '+' : '-',
                  nurse_local_time_offset / 60,
                  ')'
                ].join('')

              }
            </Descriptions.Item>
            <Descriptions.Item label="Тип осмотра">
              {examType}
            </Descriptions.Item>
            <Descriptions.Item label="Номер попытки">
              {exam.attempt_number || 1}
            </Descriptions.Item>
            <Descriptions.Item label="Статус">
              <Badge
                status={status !== 'signed' ? 'processing' : 'success'}
                text={statusText}
              />
            </Descriptions.Item>
            

            <Descriptions.Item label="А/Д (мм рт. ст.)" span={1}>
              {exam.sysValue !== null && (
                <span>
                  <Tag
                    color={isSysNormal ? 'green' : '#f50'}
                    style={{ fontSize: '18px', lineHeight: 'inherit' }}
                  >
                    {exam.sysValue}
                  </Tag>
                  &nbsp;/&nbsp;
                  <Tag
                    color={isDiaNormal ? 'green' : '#f50'}
                    style={{ fontSize: '18px', lineHeight: 'inherit' }}
                  >
                    {exam.diaValue}
                  </Tag>
                </span>
              )}
              <br />
              {
                exam.sysValue !== null && (`${[exam.sysMin, 'САД ', exam.sysMax].join('⩽')}`)
              }
              <br />
              {
                exam.sysValue !== null && (`${[exam.diaMin, 'ДАД', exam.diaMax].join(' ⩽ ')}`)
              }
            </Descriptions.Item>
            <Descriptions.Item label="Пульс (уд./мин)">
              {exam.pulseValue !== null
                && (
                  <span>
                    <Tag
                      color={isPulseNormal ? 'green' : '#f50'}
                      style={{ fontSize: '18px', lineHeight: 'inherit' }}
                    >
                      {exam.pulseValue}
                    </Tag>
                  </span>
                )}
              <br />
              {exam.pulseValue !== null && `${[exam.pulseMin, 'П', exam.pulseMax].join(' ⩽ ')}`}
            </Descriptions.Item>
            <Descriptions.Item label="Температура (°C)">
              {exam.temperatureValue !== null
                && (
                  <span>
                    <Tag
                      color={isTemperatureNormal ? 'green' : '#f50'}
                      style={{ fontSize: '18px', lineHeight: 'inherit' }}
                    >
                      {exam.temperatureValue}
                    </Tag>
                  </span>
                )}
              <br />
              {exam.temperatureValue !== null && `${['Т', exam.temperatureMax].join(' ⩽ ')}`}
            </Descriptions.Item>
            <Descriptions.Item label="Алкоголь (‰)">
              {exam.alcoholValue !== null
                && (
                  <span>
                    <Tag
                      color={isAlcoholNormal ? 'green' : '#f50'}
                      style={{ fontSize: '18px', lineHeight: 'inherit' }}
                    >
                      {exam.alcoholValue}
                    </Tag>
                  </span>
                )}
            </Descriptions.Item>
            <Descriptions.Item label="Жалобы" span={5}>
              {status !== 'active' &&
                <Tag
                  color={!exam.complaints ? 'green' : '#f50'}
                  style={{ fontSize: '18px', lineHeight: 'inherit', whiteSpace: 'break-spaces' }}
                >
                  {exam.complaints || 'жалоб нет'}
                </Tag>

              }
            </Descriptions.Item>
            <Descriptions.Item label="Решение о допуске" span={5}>
              <Row gutter={{
                xs: 8, sm: 16, md: 24, lg: 32,
              }}
              >
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={this.setAllowancesSuccess(exam.id, true)}
                  hidden={status !== 'pendingAllowance'}
                  loading={submitting}
                >
                  Допустить
                </Button>
                <Button
                  type="primary"
                  danger
                  block
                  size="large"
                  onClick={this.setAllowancesSuccess(exam.id, false)}
                  hidden={status !== 'pendingAllowance'}
                  loading={submitting}
                >
                  Не допустить
                </Button>
                {status === 'signed' &&
                  <Tag
                    color={exam.allowance ? 'green' : '#f50'}
                    style={{ fontSize: '18px', lineHeight: 'inherit', whiteSpace: 'break-spaces' }}
                  >
                    {conclusionText}
                  </Tag>

                }
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </Row >
        <Row style={{ marginTop: '18px' }}>
          <Descriptions title="Визуальный осмотр" bordered column={3}>
            <Descriptions.Item label="Контрольное фото">
              {
                employee.control_photo_id === null
                  ? avatar
                  : (
                    <img
                      alt="Контрольное фото"
                      src={api.userAvatar(employee.id)}
                      style={{ maxHeight: '180px', maxWidth: '280px' }}
                    />
                  )
              }
            </Descriptions.Item>
            <Descriptions.Item label="Визуальный осмотр" span={2}>
              <Row>
                {status !== 'active' && (
                  <>
                    <Row>
                      <Col>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={this.handlePreview}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {!!exam.video_file_uuid && (
                          <video width="448" height="240" controls>
                            <source src={api.examVideo(exam.id)} type="video/mp4" />
                          </video>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row style={{ marginTop: '18px' }}>
          <Descriptions title="Сотрудник" bordered column={4}>
            <Descriptions.Item label="Фамилия имя отчество">
              {[employee.lastName, employee.firstName, employee.patronymic].join(' ')}
            </Descriptions.Item>
            <Descriptions.Item label="Дата рождения:">
              {moment(employee.dateOfBirth).format('L')}
            </Descriptions.Item>
            <Descriptions.Item label="Возраст (полных лет)">
              {moment().diff(employee.dateOfBirth, 'years')}
            </Descriptions.Item>
            <Descriptions.Item label="Пол">
              {!employee.sex ? 'мужской' : 'женский'}
            </Descriptions.Item>
            <Descriptions.Item label="Группа риска">
              <ul>
                {(riskReferences.SYS || riskReferences.DIA) && <li>Давление</li>}
                {riskReferences.PULSE && <li>Пульс</li>}
                {riskReferences.TEMPERATURE && <li>Температура</li>}
                {riskReferences.ALCOHOL && <li>Алкоголь</li>}
                {riskReferences.AGE && <li>Возраст</li>}
              </ul>
            </Descriptions.Item>
            <Descriptions.Item label="Код МКБ-10">
              {riskGroup ? riskGroup.icdCode : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Диагноз">
              {riskGroup ? riskGroup.diagnos : ''}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row style={{ marginTop: '18px' }}>
          <Descriptions title="Договор" bordered column={2}>
            <Descriptions.Item label="Организация работника">
              {customer.legalName}
            </Descriptions.Item>
            <Descriptions.Item label="Исполнитель">
              {contractor.legalName}
            </Descriptions.Item>
            <Descriptions.Item label="Медицинский работник">
              {[nurse && nurse.lastName, nurse && nurse.firstName, nurse && nurse.patronymic].join(' ')}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Row style={{ marginTop: '18px' }}>
          <Descriptions title="Параметры окружающей среды" bordered column={1}>
            <Descriptions.Item label="Влажность">
              {[exam.ambient_humidity, '%'].join(' ')}
            </Descriptions.Item>
            <Descriptions.Item label="Освещенность">
              {[exam.ambient_light, 'Лк'].join(' ')}
            </Descriptions.Item>
            <Descriptions.Item label="Температура">
              {[exam.ambient_temperature, '°C'].join(' ')}
            </Descriptions.Item>
            <Descriptions.Item label="Координаты">
              55.855669, 37.408123
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Row style={{ marginTop: '18px' }}>
          <Descriptions title="История осмотра" bordered column={2}>
            {/* {(exam.time_exam_start) && (<Descriptions.Item label="Начало осмотра">
              
            </Descriptions.Item>)} */}
            <Timeline>
              {
                exam.time_exam_start && (
                  <Timeline.Item>Начало осмотра работником {[
                    moment
                      .utc(exam.time_exam_start)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_complaints_start && (
                  <Timeline.Item>Начало опроса на наличие жалоб {[
                    moment
                      .utc(exam.time_complaints_start)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_complaints_end && (
                  <Timeline.Item>Окончание опроса на наличие жалоб {[
                    moment
                      .utc(exam.time_complaints_end)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_alcohol_start && (
                  <Timeline.Item>Начало измерения концентрации алкоголя в выдыхаемом воздухе {[
                    moment
                      .utc(exam.time_alcohol_start)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_alcohol_end && (
                  <Timeline.Item>Окончание измерения концентрации алкоголя в выдыхаемом воздухе {[
                    moment
                      .utc(exam.time_alcohol_end)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }

              {
                exam.time_temperature_start && (
                  <Timeline.Item>Начало измерения температуры тела {[
                    moment
                      .utc(exam.time_temperature_start)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_temperature_end && (
                  <Timeline.Item>Окончание измерения температуры тела {[
                    moment
                      .utc(exam.time_temperature_end)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_bloodpressure_start && (
                  <Timeline.Item>Начало измерения артериального давления и пульса {[
                    moment
                      .utc(exam.time_bloodpressure_start)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_bloodpressure_end && (
                  <Timeline.Item>Окончание измерения артериального давления и пульса {[
                    moment
                      .utc(exam.time_bloodpressure_end)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_result_confirm && (
                  <Timeline.Item>Подтверждение результатов осмотра работником {[
                    moment
                      .utc(exam.time_result_confirm)
                      .zone(-(terminal_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    terminal_local_time_offset > 0 ? '+' : '-',
                    terminal_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_exam_uploaded && (
                  <Timeline.Item>Загрузка медицинского осмотра на сервер АИС {[
                    moment
                      .utc(exam.time_exam_uploaded)
                      .zone(-(nurse_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    '+',
                    0,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_pending_decision_start && (
                  <Timeline.Item>Начало рассмотрения результатов осмотра МР {[
                    moment
                      .utc(exam.time_pending_decision_start)
                      .zone(-(nurse_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    nurse_local_time_offset > 0 ? '+' : '-',
                    nurse_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
              {
                exam.time_nurse_signing_end && (
                  <Timeline.Item>Вынесение заключения МР {[
                    moment
                      .utc(exam.time_nurse_signing_end)
                      .zone(-(nurse_local_time_offset
                        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
                      .format('DD.MM.YY k:mm:ss.SSS'),
                    ' (UTC',
                    nurse_local_time_offset > 0 ? '+' : '-',
                    nurse_local_time_offset / 60,
                    ')'
                  ].join('')}
                  </Timeline.Item>
                )
              }
            </Timeline>
          </Descriptions>
        </Row>

      </div >
    );
  }
}

export default withRouter(ExamPage);
