import {
  Alert, Badge, Col, Radio, Row, Skeleton, Table, Tag,
} from 'antd';
import { ColumnProps } from 'antd/es/table';
import { RadioChangeEvent } from 'antd/lib/radio';
import cn from 'classnames';
import fp from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import connect from '../../connect';
import * as routes from '../../routes_';
import selectors from '../../selectors';

const mapsStateToProps = (state: any) => {
  const startOfDay = moment().set('hour', 0).set('minute', 0).set('seconds', 0)
    .set('millisecond', 0);
  const endOfDay = moment().set('hour', 23).set('minute', 59).set('seconds', 59)
    .set('millisecond', 999);

  const processingFilters = {
    status: ['pendingAllowance', 'pendingEmployeeSign', 'pendingNurseSign'],
    startOfDay: moment().subtract(15, 'minutes'),
    // startOfDay,
    endOfDay,
    NurseId: state.user.id,
  };

  const pendingAllowanceFilters = {
    status: ['pendingAllowance'],
    startOfDay: moment().subtract(15, 'minutes'),
    // startOfDay,
    endOfDay,
    NurseId: state.user.id,
  };

  const { fetchingExamsStatus } = state.exams;
  const loading = fetchingExamsStatus === 'requested';

  const props = {
    currentNurseMode: state.user.approvalMode,
    contracts: state.contracts.entities,
    employees: state.employees.entities.users,
    processingExams: selectors.exams(state, { filter: processingFilters }),
    pendingAllowanceExams: selectors.exams(state, { filter: pendingAllowanceFilters }),
    loading,
    user: state.user,
    isCertValid: !!state.certs.currentCertificate.thumbprint,
  };
  return props;
};

@connect(mapsStateToProps) class OnlineJournalPage extends React.Component<any, any> {
  componentDidMount() {
    const {
      pendingAllowanceExams,
      pendingAllowance,
      isCertValid,
    } = this.props;

    if (!isCertValid) return;

    pendingAllowanceExams.forEach((exam) => {
      pendingAllowance({ exam });
    });
  }

  handleNurseModeChange = ({ target: { value: mode } }: RadioChangeEvent) => {
    const { updateCurrentMode } = this.props;
    updateCurrentMode({ mode });
  };

  handleRowClick = ({ currentTarget }) => {
    const { push } = this.props;
    const id = currentTarget.getAttribute('data-row-key');
    push(routes.exam(id));
  };

  render() {
    const {
      currentNurseMode: nurseMode,
      contracts,
      employees,
      processingExams,
      pendingAllowanceExams,
      loading,
      isCertValid,
    } = this.props;

    if (loading) return <Skeleton active />;

    if (!isCertValid) {
      return <Alert message="Отсуствует ЭЦП" type="error" />;
    }

    const columns: ColumnProps<any>[] = [
      {
        title: '№',
        dataIndex: 'id',
      },
      {
        title: '№ п.',
        dataIndex: 'attempt_number',
      },
      {
        title: 'Дата и время',
        dataIndex: 'updatedAt',
        render: (text) => moment(text).format('DD.MM.YY k:mm:ss'),
        defaultSortOrder: 'descend',
        sorter: (exam1, exam2): any => {
          if (moment(exam1.updatedAt).isBefore(exam2.updatedAt)) {
            return -1;
          }
          if (moment(exam1.updatedAt).isAfter(exam2.updatedAt)) {
            return 1;
          }

          return 0;
        },
      },
      {
        title: 'Ф.И.О.',
        render: (text, record) => {
          const employee = employees[record.Worker];
          return employee && [
            employee.lastName,
            employee.firstName,
            employee.patronymic,
          ].join(' ');
        },
      },
      {
        title: 'Дата рождения',
        render: (text, { Worker }) => employees[Worker] && moment(employees[Worker].dateOfBirth).format('DD.MM.YY'),
      },
      {
        title: 'Организация',
        dataIndex: 'Contract[Customer][legalName]',
        render: (text, record) => {
          const contract = contracts.contracts[record.Contract];
          const customer = contracts.companies[contract.Customer];
          return customer.shortName;
        },
        onFilter: (value, exam) => {
          const contract = contracts.contracts[exam.Contract];
          const company = contracts.companies[contract.Customer];
          return company.shortName.indexOf(value) === 0;
        },
        filters: Object
          .keys(fp.groupBy('shortName')(contracts.companies))
          .map((key) => ({ text: key, value: key })),
      },
      {
        title: 'АД (мм рт. ст.)',
        render: (text, record) => (record.sysValue && record.diaValue)
          && [record.sysValue, record.diaValue].join('/'),
      },
      {
        title: 'Пульс (уд./мин)',
        dataIndex: 'pulseValue',
      },
      {
        title: 'Температура тела (°C)',
        dataIndex: 'temperatureValue',
      },
      {
        title: 'Проба на алкоголь (‰)',
        dataIndex: 'alcoholValue',
      },
      {
        title: 'Код водителя',
        dataIndex: 'Worker[id]',
        render: (text, record) => record.Worker,
      },
      {
        title: 'Тип осмотра',
        render: (text, { ExamType }) => {
          return ExamType === 1
            ? 'предрейсовый'
            : ExamType === 2
              ? 'послерейсовый'
              : ExamType === 3
                ? 'предсменный' :
                ExamType === 4
                  ? 'послесменный'
                  : 'в течение рабочего дня';
        },
      },
      {
        title: 'Статус',
        render: (text, record) => record.allowance !== null && (
          <span>
            {
              record.status === 'pendingAllowance'
                ? 'Ожидает решения'
                : 'Формирование подписи'
            }
          </span >
        ),
      },
    ];

    const isRiskGroupRecord = (record) => cn({
      'examTable-row-riskGroup': record.Worker.RiskGroup,
    });

    return (
      <div style={{ backgroundColor: 'white' }}>

        <Table
          columns={columns}
          dataSource={processingExams}
          rowKey="id"
          size='small'
          title={
            () => (
              <Row gutter={{ xs: 0, sm: 0, md: 0 }} justify="space-between" wrap={false}>
                <Col>
                  <h4>
                    Текущие осмотры &nbsp;(ожидают допуска:
                    <Badge showZero count={pendingAllowanceExams.length} />
                    )
                  </h4>
                </Col>
                <Col>
                  <Radio.Group
                    defaultValue={nurseMode}
                    buttonStyle="solid"
                    onChange={this.handleNurseModeChange}
                  >
                    <Radio.Button value="auto">Автоматический</Radio.Button>
                    <Radio.Button value="semi-auto">Полу-автоматический</Radio.Button>
                    <Radio.Button value="manual">Ручной режим</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            )
          }
          pagination={{ responsive: true, position: ['bottomLeft'], defaultPageSize: 15 }}
          onRow={() => ({
            onClick: (event) => {
              document.body.style.cursor = 'auto';
              this.handleRowClick(event);
            },
          })}
          rowClassName={isRiskGroupRecord}
        />
      </div >
    );
  }
}

export default OnlineJournalPage;
